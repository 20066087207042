import React from "react";
import classNames from "classnames";

import MenuItem from "./menuItem";

const PrimaryLinks = ({
  links,
  className,
}: {
  // TODO: better type for links
  links: any[];
  className?: string;
}) => {
  const styles = classNames("ssc-flex ssc-flex-col", className);

  return (
    // TODO: Could this have an aria-label?
    <ul className={styles} role="tree">
      {links.map((item, i) => (
        <MenuItem
          trackableClass={item.trackable_class}
          url={item.url}
          title={item.title}
          submenu={item.submenus}
          key={item.url}
          className={i > 0 ? "ssc-mt-md" : null}
        />
      ))}
    </ul>
  );
};

export default PrimaryLinks;
