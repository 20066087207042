import classNames from "classnames";
import { customColors } from "../lib/colors.js";
import React from "react";

type ContentLabelProps = {
  level: "registered" | "member" | "pro";
  colorClass?: keyof typeof customColors;
};

const ContentLabel = ({ level, colorClass = "cerise" }: ContentLabelProps) => {
  const backgroundClass = `bg-${String(colorClass)}`;

  const outerClasses = classNames(
    "inline-block text-white px-5 py-2 uppercase tracking-widest text-3xs",
    backgroundClass
  );

  return <div className={outerClasses}>{level}</div>;
};

export default ContentLabel;
