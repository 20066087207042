import React from "react";
import classNames from "classnames";

import styles from "./dropdownLink.module.css";
import DropdownSubMenu from "./dropdownSubMenu";

const DropdownLink = ({ title, trackableClass, url, submenu }) => {
  const linkClass = classNames("ssc-mr-2 ssc-font-bold", trackableClass);

  return (
    <li
      className={classNames(
        "ssc-h-full ssc-text-sm ssc-transition-colors ssc-duration-300 hover:ssc-text-cerise ssc-relative",
        styles.container
      )}
    >
      <a className={linkClass} href={url}>
        {title}
      </a>
      {submenu ? <DropdownSubMenu items={submenu} /> : null}
    </li>
  );
};

export default DropdownLink;
