import React from "react";
import classNames from "classnames";

import styles from "./dropdownSubMenu.module.css";

import { ReactComponent as ArrowRightDark } from "../../../icons/assets/arrow-right-dark.svg";

const Item = ({ title, url }) => (
  <li key={title} className="ssc-text-black">
    <a
      href={url}
      className="ssc-transition-colors ssc-duration-300 hover:ssc-text-cerise ssc-flex ssc-items-center ssc-justify-between ssc-w-full ssc-leading-none"
    >
      <p
        className="ssc-mb-1 ssc-mr-md"
        dangerouslySetInnerHTML={{ __html: title }}
      />
      <ArrowRightDark />
    </a>
  </li>
);

const DropdownSubMenu = ({ items }) => {
  const itemCount = items.length;
  const gridRows = itemCount >= 8 ? 8 : itemCount;

  const containerClasses = classNames(
    "ssc-absolute ssc-grid ssc-grid-flow-col ssc-col-gap-6 ssc-row-gap-2 ssc-list-none",
    `ssc-grid-rows-${gridRows}`,
    "ssc-bg-white ssc-rounded-md ssc-py-2xl ssc-px-xl ssc-justify-center ssc-whitespace-no-wrap ssc-text-xs ssc-w-auto",
    styles.container
  );
  const subMenuItems = items.map((item) => <Item key={item.title} {...item} />);
  return <ul className={containerClasses}>{subMenuItems}</ul>;
};

export default DropdownSubMenu;
