// eslint-disable-next-line no-unused-vars
import React, { MouseEventHandler } from "react";
import classNames from "classnames";

import PianoShowIfLoggedIn from "piano/pianoShowIfLoggedIn";
import PianoShowIfLoggedOut from "piano/pianoShowIfLoggedOut";

declare global {
  interface Window {
    tp: any;
  }
}

type Variant = "white" | "cerise";

type AnchorType = {
  text: string;
  TagName: "a";
  href: string;
  variant?: Variant;
  onClick?: MouseEventHandler<HTMLElement>;
  className?: string;
};

type ButtonType = {
  text: string;
  TagName: "button";
  href?: undefined;
  variant?: Variant;
  onClick: MouseEventHandler<HTMLElement>;
  className?: string;
};

type ButtonProps = AnchorType | ButtonType;

const Button = ({
  text,
  TagName,
  href,
  variant = "cerise",
  onClick,
  className,
}: ButtonProps) => {
  const classes = classNames(
    "ssc-outline-none focus:ssc-outline-none ssc-px-sm md:ssc-px-md ssc-py-sm ssc-leading-none ssc-text-xs md:ssc-text-sm",
    className,
    {
      "ssc-bg-cerise ssc-text-white": variant === "cerise",
      "ssc-bg-white ssc-text-cerise": variant === "white",
    }
  );
  return (
    <TagName className={classes} href={href} onClick={onClick}>
      {text}
    </TagName>
  );
};

const WhiteButton = (props) => {
  return <Button {...props} variant="white" />;
};

const CeriseButton = (props) => {
  return <Button {...props} variant="cerise" />;
};

const AccountButtons = () => {
  return (
    <ul className="ssc-cursor-pointer ssc-select-none ssc-font-bold ssc-flex lg:ssc-ml-2xl">
      <PianoShowIfLoggedIn>
        <li>
          <WhiteButton
            text="My profile"
            href="/members/profile"
            TagName="a"
            className="ssc-hidden lg:ssc-block"
          />
        </li>
      </PianoShowIfLoggedIn>
      <PianoShowIfLoggedIn>
        <li>
          <CeriseButton
            text="Log out"
            onClick={() => window.tp?.pianoId.logout()}
            TagName="button"
            className="ssc-block lg:ssc-ml-md"
          />
        </li>
      </PianoShowIfLoggedIn>
      <PianoShowIfLoggedOut>
        <li>
          <WhiteButton
            text="Sign in"
            onClick={() => window.tp?.pianoId.show({ screen: "login" })}
            TagName="button"
            className="ssc-hidden lg:ssc-block"
          />
        </li>
      </PianoShowIfLoggedOut>
      <PianoShowIfLoggedOut>
        <li>
          <CeriseButton
            text="Sign up"
            href="/members/profile"
            TagName="a"
            className="ssc-block lg:ssc-ml-md"
          />
        </li>
      </PianoShowIfLoggedOut>
    </ul>
  );
};

export default AccountButtons;
