import React from "react";
import { ReactComponent as SearchIcon } from "../icons/assets/search.svg";

const Search = () => {
  return (
    <a
      href="https://sifted.eu/?s=2021"
      target="_blank"
      rel="noreferrer"
      className="ssc-z-50 ssc-text-2xs ssc-hover:text-cerise ssc-transition-colors ssc-duration-300"
    >
      <SearchIcon className="ssc-w-4 ssc-h-4 ssc-fill-current" />
    </a>
  );
};

export default React.memo(Search);
