// eslint-disable-next-line no-unused-vars
import React, { Fragment, MouseEventHandler, useEffect } from "react";
import classNames from "classnames";

import SocialIcons from "./socialIcons/socialIcons";
import PrimaryLinks from "./primaryLinks/primaryLinks";
import ProfileMenu from "./profileMenu/profileMenu";

export const SIDEBAR_ARIA_CONTROLS_LABEL = "sidebar";

const Link = ({
  title,
  url,
  trackableClass,
}: {
  title: string;
  url: string;
  trackableClass?: string;
}) => {
  const classes = classNames(
    "ssc-text-xs hover:ssc-text-cerise ssc-transition-colors ssc-duration-300",
    trackableClass
  );
  return (
    <a className={classes} href={url}>
      {title}
    </a>
  );
};

const renderLinks = (links) => {
  return (
    <ul>
      {links.map((link, index) => (
        <li key={index}>
          <Link
            title={link.title}
            url={link.url}
            trackableClass={link.trackable_class}
          />
        </li>
      ))}
    </ul>
  );
};

// TODO: More comprehensive types
type SidebarProps = {
  opened?: boolean;
  sidePrimary: any;
  sideSecondary: any;
  sideTertiary: any;
  onClickOutside: MouseEventHandler<HTMLElement>;
};

const Sidebar = ({
  opened = true,
  sidePrimary,
  sideSecondary,
  sideTertiary,
  onClickOutside,
}: SidebarProps) => {
  useEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;

    // Prevent background from scrolling when sidebar is open
    if (opened) {
      document.body.style.overflow = "ssc-hidden";
    } else {
      document.body.style.overflow = originalStyle;
    }
    // Re-enable scrolling when component unmounts
    return function cleanup() {
      document.body.style.overflow = originalStyle;
    };
  }, [opened]);

  const animationClasses = classNames(
    "ssc-transform ssc-transition-transform ssc-ease-out ssc-duration-200 motion-reduce:ssc-transition-none motion-reduce:ssc-transform-none",
    opened ? "ssc-translate-x-0" : "ssc--translate-x-100"
  );

  const containerClasses = classNames(
    "ssc-fixed ssc-h-screen ssc-top-0 ssc-z-40 ssc-px-lg md:ssc-px-4xl ssc-pb-lg ssc-w-full lg:ssc-w-1/4 ssc-bg-dark-blue ssc-text-white ssc-overflow-y-auto",
    animationClasses
  );

  const backgroundClasses = classNames(
    "ssc-absolute ssc-top-0 ssc-left-0 ssc-overflow-x-hidden ssc-w-screen ssc-h-screen ssc-bg-black ssc-max-w-full",
    "ssc-transition-opacity ssc-duration-200 motion-reduce:ssc-transition-none",
    {
      "ssc-visible ssc-opacity-75": opened,
      "ssc-invisible ssc-opacity-0": !opened,
    }
  );

  return (
    <Fragment>
      <aside
        id={SIDEBAR_ARIA_CONTROLS_LABEL}
        className={containerClasses}
        aria-label="Sidebar navigation"
        aria-hidden={opened ? null : "true"}
      >
        <div className="ssc-mt-5xl ssc-pt-lg">
          <ProfileMenu />
        </div>

        <hr className="ssc-mt-xl ssc-w-1/4 ssc-opacity-50" />
        <div className="ssc-mt-xl">
          <PrimaryLinks links={sidePrimary} />
        </div>

        <hr className="ssc-mt-xl ssc-w-1/4 ssc-opacity-50" />
        <div className="ssc-mt-xl">{renderLinks(sideSecondary)}</div>

        <hr className="ssc-mt-xl ssc-w-full ssc-opacity-50" />
        <div className="ssc-mt-xl">{renderLinks(sideTertiary)}</div>

        <hr className="ssc-mt-xl ssc-w-full ssc-opacity-50" />
        <div className="ssc-w-1/2 md:ssc-w-1/4 lg:ssc-w-4/5 ssc-mt-xl">
          <SocialIcons />
        </div>
      </aside>
      <div
        className={backgroundClasses}
        onClick={onClickOutside}
        aria-hidden="true"
      />
    </Fragment>
  );
};

export default Sidebar;
