import React, { Fragment } from "react";

import PianoShowIfLoggedIn from "piano/pianoShowIfLoggedIn";
import PianoShowIfLoggedOut from "piano/pianoShowIfLoggedOut";

const ProfileMenu = () => {
  return (
    <Fragment>
      <PianoShowIfLoggedOut>
        <button
          aria-label="Sign in"
          className="ssc-block ssc-font-bold hover:ssc-text-cerise ssc-transition-colors ssc-duration-300"
          onClick={() => window.tp.pianoId.show({ screen: "login" })}
        >
          Sign In
        </button>
        <a
          href="/members/products"
          className="ssc-mt-xs ssc-font-bold ssc-block hover:ssc-text-cerise ssc-transition-colors ssc-duration-300"
        >
          Sign up
        </a>
      </PianoShowIfLoggedOut>
      <PianoShowIfLoggedIn>
        <a
          href="/members/profile"
          className="ssc-block ssc-font-bold hover:ssc-text-cerise ssc-transition-colors ssc-duration-300"
        >
          My profile
        </a>

        <button
          aria-label="Log out"
          onClick={() => window.tp.pianoId.logout()}
          className="ssc-mt-xs ssc-font-bold ssc-block hover:ssc-text-cerise ssc-transition-colors ssc-duration-300"
        >
          Log out
        </button>
      </PianoShowIfLoggedIn>
    </Fragment>
  );
};

export default ProfileMenu;
