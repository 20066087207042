import React, { useEffect, useState } from "react";
import classNames from "classnames";
import raf from "raf";

import { SIFTED_WP_SITE } from "../lib/constants";
import styles from "./navigation.module.css";

import { ReactComponent as SiftedLogoWhite } from "../icons/assets/sifted-logo-white.svg";
import { ReactComponent as SiftedLogoCerise } from "../icons/assets/sifted-logo-cerise.svg";
import Sidebar, { SIDEBAR_ARIA_CONTROLS_LABEL } from "./sidebar/sidebar";
import BurgerButton from "./burgerButton/burgerButton";
import PrimaryLinks from "./primaryLinks/primaryLinks";
import Search from "./search";
import AccountButtons from "./accountButtons";

const SCROLL_THRESHOLD = 0;

type LogoVariants = "cerise" | "white";

const NavLogo = ({
  variant = "cerise",
  className,
}: {
  variant?: LogoVariants;
  className?: string;
}) => (
  <a
    href={SIFTED_WP_SITE}
    className={classNames("ssc-z-50 ssc-h-2xl md:ssc-h-3xl", className)}
  >
    <span
      className={classNames({ "ssc-hidden": variant !== "white" }, "ssc-block")}
    >
      <SiftedLogoWhite height="40px" />
    </span>
    <span
      className={classNames(
        { "ssc-hidden": variant !== "cerise" },
        "ssc-block"
      )}
    >
      <SiftedLogoCerise height="40px" />
    </span>
  </a>
);

// TODO: Better type for menuItems
type NavigationProps = {
  menuItems: any;
  variant?: "transparent" | "opaque";
};

type NavStates = "scrollingUp" | "scrollingDown" | "top";

const Navigation = ({
  menuItems,
  variant = "transparent",
}: NavigationProps) => {
  const { topPrimary, sidePrimary, sideTertiary, secondary } = menuItems;

  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [navState, setNavState] = useState<NavStates>("top");

  useEffect(() => {
    let prevScrollpos = window.pageYOffset;

    function onScroll() {
      // raf is a polyfill wrapper for RequestAnimationFrame and it is used to improve the
      // performance of this function in the scroll event.
      // More info: https://www.html5rocks.com/en/tutorials/speed/animations/
      raf(function tick() {
        const currentScrollPos = window.pageYOffset;
        const scrollingUp = prevScrollpos > currentScrollPos;
        const reachedTop = currentScrollPos <= SCROLL_THRESHOLD;

        if (scrollingUp) {
          setNavState("scrollingUp");
        } else {
          setNavState("scrollingDown");
        }

        if (reachedTop) {
          setNavState("top");
        }

        prevScrollpos = currentScrollPos;
      });
    }

    window.addEventListener("scroll", onScroll);

    return function cleanup() {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  const logoVariantToShow =
    (navState === "scrollingUp" || variant === "opaque") && !sidebarOpened
      ? "cerise"
      : "white";

  return (
    <nav
      id="navbar"
      className={classNames(
        "ssc-fixed ssc-top-0 ssc-left-0 ssc-w-full ssc-z-20 ssc-transform ssc-transition-all ssc-duration-200",
        {
          "ssc-bg-white ssc-text-black":
            variant === "opaque" || navState === "scrollingUp",
          "ssc--translate-y-100": navState === "scrollingDown",
          "ssc-text-white": navState === "top" && variant === "transparent",
        }
      )}
    >
      <div className="ssc-flex ssc-items-center ssc-justify-between ssc-p-md">
        <div className="ssc-z-50 ssc-flex ssc-items-center ssc-justify-between">
          <div className="ssc-text-white ssc-burger-button">
            <BurgerButton
              opened={sidebarOpened}
              ariaControls={SIDEBAR_ARIA_CONTROLS_LABEL}
              className={classNames({
                "ssc-text-white":
                  navState === "top" && variant === "transparent",
                "ssc-text-black":
                  (navState === "scrollingUp" || variant === "opaque") &&
                  !sidebarOpened,
              })}
              onClick={(e) => {
                e.preventDefault();
                setSidebarOpened(!sidebarOpened);
              }}
            />
          </div>
          <NavLogo
            className={styles.largeScreenLogo}
            variant={logoVariantToShow}
          />
        </div>

        {/* Mobile variant of the logo */}
        <NavLogo
          className={styles.smallScreenLogo}
          variant={logoVariantToShow}
        />

        <PrimaryLinks
          className={classNames("ssc--mt-1", { "ssc-z-40": !sidebarOpened })}
          linkData={topPrimary}
        />

        <div className="ssc-flex ssc-items-center ssc-flex-shrink-0 ssc-text-sm">
          <div className="ssc-hidden lg:ssc-block">
            <Search />
          </div>
          <div className={styles.accountButtons}>
            <AccountButtons />
          </div>
        </div>
      </div>
      <Sidebar
        opened={sidebarOpened}
        sidePrimary={sidePrimary}
        sideSecondary={secondary}
        sideTertiary={sideTertiary}
        onClickOutside={() => setSidebarOpened(!sidebarOpened)}
      />
    </nav>

    //     {/* <nav aria-label="Main Navigation">
  );
};

export default React.memo(Navigation);
