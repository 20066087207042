import React, { Fragment, useState } from "react";
import classNames from "classnames";

import { ReactComponent as ArrowRightWhite } from "../../../icons/assets/arrow-right-white.svg";
import { ReactComponent as ExpandArrow } from "../../../icons/assets/expand-arrow.svg";
import { ReactComponent as CloseIcon } from "../../../icons/assets/close-thin.svg";

const Submenu = ({ title, url, submenu, trackableClass, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownClasses = classNames("ssc-flex ssc-flex-col ssc-pb-sm", {
    hidden: !isOpen,
  });

  const clickHandler = (e) => {
    const enterKey = 13;
    if (e.charCode && e.charCode !== enterKey) return;
    setIsOpen(!isOpen);
  };

  return (
    <li
      role="treeitem"
      aria-expanded={isOpen}
      onKeyPress={clickHandler}
      onClick={clickHandler}
      className={className}
      // tabIndex={0} TODO: think about this
    >
      <div className="ssc-flex ssc-items-center ssc-justify-between ssc-cursor-pointer hover:ssc-text-cerise ssc-transition-colors ssc-duration-300">
        {isOpen ? (
          <Fragment>
            <a className="ssc-font-bold" href={url}>
              {title}
            </a>
            <CloseIcon className="ssc-w-4 ssc-h-4 ssc-fill-current" />
          </Fragment>
        ) : (
          <Fragment>
            <span className="ssc-font-bold">{title}</span>
            <ExpandArrow className="ssc-w-4 ssc-fill-current" />
          </Fragment>
        )}
      </div>
      <ul className={dropdownClasses}>
        {submenu.map((item) => {
          return (
            <li
              className="ssc-text-sm hover:ssc-text-cerise ssc-transition-colors ssc-duration-300 ssc-mt-sm"
              key={item.title}
              role="none"
            >
              <a
                role="treeitem"
                href={item.url}
                dangerouslySetInnerHTML={{ __html: item.title }}
                className={trackableClass}
              />
            </li>
          );
        })}
      </ul>
    </li>
  );
};

const MenuItem = ({ title, trackableClass, url, submenu, className }) => {
  const linkClass = classNames(
    "ssc-mr-2 ssc-font-bold hover:ssc-text-cerise",
    trackableClass
  );

  if (submenu) {
    return (
      <Submenu
        title={title}
        url={url}
        submenu={submenu}
        trackableClass={trackableClass}
        className={className}
      />
    );
  }

  return (
    <li
      className={`ssc-flex ssc-items-center ssc-justify-between ${className}`}
      role="treeitem"
    >
      <a className={linkClass} href={url}>
        {title}
      </a>

      <ArrowRightWhite className="ssc-text-white ssc-opacity-75" />
    </li>
  );
};

export default MenuItem;
