import React from "react";
import classNames from "classnames";

const LOGGED_IN_CLASS = "piano__show-if-logged-in";
export const LOGGED_IN_COMPONENT_SELECTOR = `.${LOGGED_IN_CLASS}`;

// NOTE: This component is heavily controlled by Piano.jsx
// LOGGED_IN_COMPONENT_SELECTOR is used as a selector by the Piano script to
// show and hide elements depending on whether the reader is logged in or not

const PianoShowIfLoggedIn = ({ children }) => {
  return (
    <div className={classNames(LOGGED_IN_CLASS, "ssc-hidden hidden")}>
      {children}
    </div>
  );
};

export default PianoShowIfLoggedIn;
