import * as React from "react";
import styles from "./styles.module.css";
import ContentLabel from "./contentLabel/contentLabel";
import Navigation from "./navigation/navigation";

interface Props {
  text: string;
}

const ExampleComponent = ({ text }: Props) => {
  return <div className={styles.test}>Example Component: {text}</div>;
};

export { ExampleComponent, ContentLabel, Navigation };
