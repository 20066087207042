import React from "react";
import { ReactComponent as FacebookIcon } from "../../../icons/assets/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../../icons/assets/twitter-icon.svg";
import { ReactComponent as LinkedInIcon } from "../../../icons/assets/linkedin-icon.svg";
import { ReactComponent as RssIcon } from "../../../icons/assets/rss-icon.svg";

import styles from "./socialIcons.module.css";

const SocialIcon = ({ Icon, href }) => (
  <li className={styles.socialIcon}>
    <a href="href" target="_blank" rel="noreferrer">
      <Icon />
    </a>
  </li>
);

const SocialIcons = () => {
  return (
    <ul className="ssc-relative ssc-bottom-0 ssc-left-0 ssc-right-0 ssc-flex ssc-items-center ssc-justify-between">
      <SocialIcon
        Icon={FacebookIcon}
        href="https://www.facebook.com/siftedeu"
      />

      <SocialIcon Icon={TwitterIcon} href="https://twitter.com/siftedeu" />

      <SocialIcon
        Icon={LinkedInIcon}
        href="https://www.linkedin.com/company/siftedeu/"
      />

      <SocialIcon
        Icon={RssIcon}
        href="https://sifted.eu/feed/?post_type=article"
      />
    </ul>
  );
};

export default SocialIcons;
