import React from "react";
import classNames from "classnames";

import styles from "./burgerButton.module.css";

type BurgerProps = {
  opened: boolean;
  ariaControls: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
};

const BurgerButton = ({
  opened,
  ariaControls,
  onClick,
  className,
}: BurgerProps) => {
  const containerClasses = classNames(
    "md:ssc-mr-2 ssc-mb-0 ssc-flex ssc-flex-col ssc-items-center ssc-justify-evenly ssc-py-2 focus:ssc-outline-none",
    styles.container,
    className,
    {
      [styles.isActive]: opened,
    }
  );

  return (
    <button
      tabIndex={0}
      aria-haspopup="true"
      aria-expanded={opened}
      aria-controls={ariaControls}
      className={containerClasses}
      onClick={onClick}
    >
      <span className="ssc-sr-only">{`${
        opened ? "Open" : "Close"
      } navigation menu`}</span>

      <span className={styles.topSlice} />
      <span className={styles.middleSlice} />
      <span className={styles.bottomSlice} />
    </button>
  );
};

export default BurgerButton;
