import React from "react";
import classNames from "classnames";

import styles from "./primaryLinks.module.css";

import DropdownLink from "./dropdownLink/dropdownLink";

const PrimaryLinks = ({ linkData, className }) => {
  const containerClasses = classNames(
    "ssc-h-full ssc-space-x-8 lg:ssc-px-2 xl:ssc-px-2 2xl:ssc-mx-14 lg:ssc-flex lg:ssc-h-full",
    styles.container,
    className
  );

  return (
    <ul className={containerClasses} role="menubar">
      {linkData.map((item) => (
        <DropdownLink
          trackableClass={item.trackable_class}
          url={item.url}
          title={item.title}
          submenu={item.submenus}
          key={item.title}
        />
      ))}
    </ul>
  );
};

export default React.memo(PrimaryLinks);
